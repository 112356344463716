var identityWindow;
var NFTsoldOutMessage = "That's all the Jigglers for today! Check back later for more!"
var nftSocket;
var selectedQuote = 1;
var myTimer;
function getGallery(callback){
  $('#grid').html("");
  $('#grid').mediaBoxes({
    boxesToLoadStart: 8,
    boxesToLoad: 8,
    lazyLoad: true,
    getSortData: {
      rarity: '.media-box-rarity',
    },
    sortContainer: '#sort',
    search: '#search',
    searchTarget: '.media-box-title',
    noMoreEntriesWord: 'No more NFTs to load',
    filterContainer: '#filter',
    columns: 4,
    resolutions: [
      {
        maxWidth: 650,
        columnWidth: 'auto',
        columns: 2,
      },
      {
        maxWidth: 450,
        columnWidth: 'auto',
        columns: 2,
      },
    ],
  });
  var jsonURL = "https://8ukm02mrv6.execute-api.us-west-2.amazonaws.com/production/minted";
  console.log("Getting gallery for minted Jigglers");
  var propCount = 0;
  var imgList = "";
  $.ajax({
    type: 'GET',
    url: jsonURL,
    data: { get_param: 'value' },
    dataType: 'json',
    success: function (data) {
      $.each(data, function () {

        imgList += '<div class="media-box image ' + this.nftowner + '"><a href="https://nftz.zone/nft/' + this.PostHashHex + '" target="_blank"><div class="media-box-image"><div data-width="140px" data-height="140px" data-thumbnail="' + this.ImageURL + '"></div></div></a><div class="media-box-content"><div class="media-box-title">' + this.nftName + '</div><div class="media-box-text">' + this.Edition + '</div><div class="media-box-edition hidden">' + zeroPad(this.id, 3) + '</div></div></div>';
        propCount++;

      });
      $('#grid').mediaBoxes( 'insert', $(imgList), function(){});  
      $("#number-minted").html("Total Minted: " + propCount + " of 500");
      $('#gallery-loading').addClass("hidden");

    }
  });
  console.log("Done Loading Gallery from bid")
  callback();
}
function processGallery() {
      console.log("Processing Gallery from bid")
      
  }
function minTwoDigits(n) {
  return (n < 10 ? '0' : '') + n;
}


function clock(time) {
  myTimer = setInterval(myClock, 1000);
  var c = time;


  function myClock() {
    --c
    const quotes = [
      "Jiggling body parts…",
      "Wobbling heads…",
      "Wiggling eyebrows…",
      "Throwing accessories at the wall…",
      "Swiping left and right on faces…",
      "Drawing backgrounds…"]
    var seconds = minTwoDigits(c % 60); // Seconds that cannot be written in minutes
    var minutes = (c - seconds) / 60; // Gives the seconds that COULD be given in minutes
    var minutesLeft = minutes % 60; // Minutes that cannot be written in hours
    var hours = (minutes - minutesLeft) % 60;
    if(seconds % 2 == 0) {

      
      selectedQuote = selectedQuote +1;
      console.log(selectedQuote)
      if(selectedQuote > quotes.length) {
        selectedQuote = 0;
      }

    }
    // Now in hours, minutes and seconds, you have the time you need.
    $('#processing-quote').text(quotes[selectedQuote])
    $('#clock').html("Wait time: " + hours + ":" + minutes + ":" + seconds);   
    if (c == 0) {
      clearInterval(myTimer);
      c = 1;
      $('#clock').html("Processing..."); 
    }
  }
}

function sendMessage(ws, msg) {
  // Wait until the state of the socket is not ready and send the message when it is...
  waitForSocketConnection(ws, function () {
    console.log("message sent!!!");
    console.log(msg);
    ws.send(msg);
  });
}

// Make the function wait until the connection is made...
function waitForSocketConnection(socket, callback) {
  setTimeout(
    function () {
      if (socket.readyState === 1) {
        console.log("Connection is made")
        if (callback != null) {
          callback();
        }
      } else {
        console.log("wait for connection...")
        waitForSocketConnection(socket, callback);
      }

    }, 5); // wait 5 milisecond for the connection...
}

function logout() {
  var logoutURL = 'https://identity.deso.org/logout?publicKey=' + localStorage.getItem("userPublicKey");
  identityWindow = window.open(logoutURL, null, 'toolbar=no, width=800, height=1000, top=0, left=0');
}

function login() {
  $("#minted-nft").addClass("hidden")
  $("#bid-status").addClass("hidden")
  //$('html, body').animate({
  //  scrollTop: $("#instructions").offset().top
  //}, 1000);
  $("#login-button").addClass("is-loading");
  identityWindow = window.open('https://identity.deso.org/log-in', null, 'toolbar=no, width=800, height=1000, top=0, left=0');    
  }

function bid() {
  $([document.documentElement, document.body]).animate({
    scrollTop: $("#topofmint").offset().top
}, 1000);


  var initial_val = $("#name").val(); 
  var _val = initial_val.toLowerCase()
  var found = false;
  $.each(wordlist, function(key, value) {
      if (_val.indexOf(value) != -1) {
        Swal.fire({icon: 'error', title: 'Nice try...',text: 'We know your a degen, but please refrain from using profanity.', confirmButtonColor: '#000000'})
        found = true
      } 
  });
  $.each(['@', '$'], function(key, value) {
    if (_val.indexOf(value) != -1) {
      Swal.fire({icon: 'error', title: 'Sorry...',text: 'While it might be funny to tag people, probably not a good idea. You cannot use the @ or $ symbol.', confirmButtonColor: '#000000'})
      found = true
    } 
});
  if (!found) {

    mintType = 'mint'
    $("#login-button").addClass("is-loading");
    $("#bid-status").addClass("hidden", 500)
    $("#formname").addClass("hidden", 500)
    $("#minted-nft").addClass("hidden", 500)
    $("#options").addClass("hidden", 500)
    $("#instructions").addClass("hidden", 500)
    $("#bid-button").html("<span class='spinner-border spinner-border-sm' role='status' aria-hidden='true'></span> Processing...")
    $("#bid-button").removeClass("hidden", 500)
    console.log("Clicked Select Mint");
    $("#ban-status").addClass("hidden", 500)
    var mintPrice = "1000700000"
    if(localStorage.getItem("freeMint") == "1") {
      var mintPrice = "1170000"
    }
    if(localStorage.getItem("userPublicKey") != "") {
      identityWindow = window.open('https://identity.deso.org/derive?publicKey=' + localStorage.getItem("userPublicKey") +'&expirationDays=1&transactionSpendingLimitResponse=%7B%22GlobalDESOLimit%22:' + mintPrice + ',%22TransactionCountLimitMap%22:%7B%22AUTHORIZE_DERIVED_KEY%22:4,%22BASIC_TRANSFER%22:4%7D,%22NFTOperationLimitMap%22:%7B%22%22:%7B%220%22:%7B%22nft_bid%22:1%7D%7D%7D%7D', null, 'toolbar=no, width=800, height=1000, top=0, left=0');
    } else {
      identityWindow = window.open('https://identity.deso.org/derive?expirationDays=1&transactionSpendingLimitResponse=%7B%22GlobalDESOLimit%22:' + mintPrice + ',%22TransactionCountLimitMap%22:%7B%22AUTHORIZE_DERIVED_KEY%22:4,%22BASIC_TRANSFER%22:4%7D,%22NFTOperationLimitMap%22:%7B%22%22:%7B%220%22:%7B%22nft_bid%22:1%7D%7D%7D%7D', null, 'toolbar=no, width=800, height=1000, top=0, left=0');
    }
  }

 
  



}



function handleInit(e) {
  console.log("Init");
  console.log(e);
  if (!init) {
    init = true;
    iframe = document.getElementById("identity");

    for (const e of pendingRequests) {
      postMessage(e);
    }

    pendingRequests = []
  }

  respond(e.source, e.data.id, {})
}

function handleApprove(e) {
  console.log("Approve");
  console.log(e);
  if (!init) {
    init = true;
    iframe = document.getElementById("identity");

    for (const e of pendingRequests) {
      postMessage(e);
    }

    pendingRequests = []
  }
  localStorage.setItem("id", e.data.id);
  respondApprove(e.source, e.data.id, {})
}

function retryApprove(e) {
  identityWindow = window.open('https://identity.deso.org/approve?tx=' + localStorage.getItem("transactionHex"), null, 'toolbar=no, width=800, height=1000, top=0, left=0');
  //console.log("Trying to change location of identity window");
  //identityWindow.location.href='https://identity.deso.org/approve?tx=';
  //identityWindow = window.open('https://identity.deso.org/approve?tx=' + localStorage.getItem("transactionHex"), null, 'toolbar=no, width=800, height=1000, top=0, left=0');
  console.log("Thinks it sent")
  $("#bid-button").html("Mint NFT")
  $('#processing-quote').addClass("hidden")
  $("#bid-button").removeClass("is-loading")
  $("#bid").addClass("hidden", 500)
  $("#options").addClass("hidden", 500)
  $("#instructions").addClass("hidden", 500)
  $('#formname').removeClass("hidden", 500)
  $("#bid-status").removeClass("hidden", 500).html("<span class='spinner-border spinner-border-sm' role='status' aria-hidden='true'></span><p>Minting and processing bid...</p>")
}

function handleLogin(payload) {
  console.log("Login");
  localStorage.setItem("freeMint", "0")
  if(payload.publicKeyAdded) {
  console.log("We are at the handleLogin");
  console.log(JSON.stringify(payload))
  publicKey = payload.publicKeyAdded;
  console.log(publicKey);
  localStorage.setItem("userPublicKey", payload.publicKeyAdded);
  if (identityWindow) {
    identityWindow.close();
    identityWindow = null;
  }

  $("#myNFTs").attr("data-filter", "." + localStorage.getItem("userPublicKey")); // Set My NFTs Gallery Filter
      //Reload Gallery
      $('#grid').addClass("hidden");
      $('#gallery-loading').removeClass("hidden");
      $('#grid').mediaBoxes('destroy');
      $('#grid').html("");
      console.log("Getting gallery");
      getGallery(processGallery);


      // Get user name
      var settings = {
        "url": "https://node.deso.org/api/v0/get-single-profile",
        "method": "POST",
        "timeout": 0,
        "headers": {
          "Content-Type": "application/json"
        },
        "data": JSON.stringify({
          "PublicKeyBase58Check": localStorage.getItem("userPublicKey")
        }),
      };

      $.ajax(settings).done(function (response) {

        var username = (response.Profile);
        localStorage.setItem("currentUser", username["Username"]);
        console.log(username["Username"]);
      });



      var data = {
        userPublicKey: localStorage.getItem("userPublicKey"),
        //derivedPublicKey: localStorage.getItem("derivedPublicKeyBase58Check"),
        //derivedSeedHex: localStorage.getItem("userEncryptedSeedHex"),
        //derivedJWT: localStorage.getItem("derivedJWT")
      }
      // Check that minter or creator royalty is not logged in first
      if(!['BC1YLgUt48Tcaahw2kFCuNh31Kv2Ep2KFKn2CXjbPET5dZYybM5gXhg', 'BC1YLiVnbgsTZggfXBpfmbJ9DiM4aTEZvET2dgowmnAkt6535TSNVYL', 'BC1YLjRYTaeLCUJDuDj5fZcQu9pb6d9qoyNMwiDheWPwBSvEzDtatQM'].includes(localStorage.getItem("userPublicKey"))) {
      $.ajax({
        url: "https://8ukm02mrv6.execute-api.us-west-2.amazonaws.com/production/initiate",
        type: "post",
        dataType: "json",
        crossDomain: "true",
        contentType: "application/json; charset=utf-8",
        data: JSON.stringify(data),

        beforeSend: function (data) {
          // nothing
          console.log("Initiating...");
        },

        success: function (data) {
          var mintInstructions = "\
                <img alt='Profile' src='https://diamondapp.com/api/v0/get-single-profile-picture/" + localStorage.getItem('userPublicKey') + "?fallback=https://bitclout.com/assets/img/default_profile_pic.png' class='rounded-circle purple-border mb-2' width='50px' height='50px'>\
                <h3 class='fw-bold mb-3'>Hi " + localStorage.getItem("currentUser") + "!</h3><p>You're all set to mint! Name your Jiggler and click the Mint button and approve the transaction and then just wait for your Jiggler to mint!</p>"
          var freeMintInstructions = "\
                <img alt='Profile' src='https://diamondapp.com/api/v0/get-single-profile-picture/" + localStorage.getItem('userPublicKey') + "?fallback=https://bitclout.com/assets/img/default_profile_pic.png' class='rounded-circle purple-border mb-2' width='50px' height='50px'>\
                <h3 class='fw-bold mb-3'>Hi " + localStorage.getItem("currentUser") + "!</h3><p>You have a free mint pass! Name your Jiggler and click the Mint button and approve the transaction and then just wait for your Jiggler to mint!</p>"
          var userProfile = "\
                <a class='navbar-link'>\
                <figure class='image is-24x24'>\
                <img class='is-rounded' src='https://diamondapp.com/api/v0/get-single-profile-picture/" + localStorage.getItem('userPublicKey') + "?fallback=https://bitclout.com/assets/img/default_profile_pic.png'>\
                </figure>\
                </a>\
                <div class='navbar-dropdown'>\
                <a class=navbar-item' onClick='logout()'>\
                Logout\
                </div>\
                </a>"
          $("#navbarlogin").html(userProfile);
          $("#navbarlogin").addClass("has-dropdown");
          $("#navbarlogin").addClass("is-hoverable");


      // Get user name
      var settings = {
        "url": "https://node.deso.org/api/v0/get-single-profile",
        "method": "POST",
        "timeout": 0,
        "headers": {
          "Content-Type": "application/json"
        },
        "data": JSON.stringify({
          "PublicKeyBase58Check": localStorage.getItem("userPublicKey")
        }),
      };

      $.ajax(settings).done(function (response) {

        var username = (response.Profile);
        localStorage.setItem("currentUser", username["Username"]);
        console.log(username["Username"]);
      });



      var data = {
        userPublicKey: localStorage.getItem("userPublicKey"),
        derivedPublicKey: localStorage.getItem("derivedPublicKeyBase58Check"),
        derivedSeedHex: localStorage.getItem("userEncryptedSeedHex"),
        derivedJWT: localStorage.getItem("derivedJWT")
      }
      $.ajax({
        url: "https://8ukm02mrv6.execute-api.us-west-2.amazonaws.com/production/initiate",
        type: "post",
        dataType: "json",
        crossDomain: "true",
        contentType: "application/json; charset=utf-8",
        data: JSON.stringify(data),

        beforeSend: function (data) {
          // nothing
          console.log("Initiating...");
        },

        success: function (data) {
          var mintInstructions = "\
                <img alt='Profile' src='https://diamondapp.com/api/v0/get-single-profile-picture/" + localStorage.getItem('userPublicKey') + "?fallback=https://bitclout.com/assets/img/default_profile_pic.png' class='is-rounded mb-2 primary-border' width='50px' height='50px'>\
                <h3 class='fw-bold mb-3'>Hi " + localStorage.getItem("currentUser") + "!</h3><p>You're all set to mint! Just click the button below and wait for your NFT to complete minting (please do not reload).</p>"
          var freeMintInstructions = "\
                <img alt='Profile' src='https://diamondapp.com/api/v0/get-single-profile-picture/" + localStorage.getItem('userPublicKey') + "?fallback=https://bitclout.com/assets/img/default_profile_pic.png' class='is-rounded mb-2 primary-border' width='50px' height='50px'>\
                <h3 class='fw-bold mb-3'>Hi " + localStorage.getItem("currentUser") + "!</h3><p>You have a free mint pass! Just click the button below and wait for your NFT to complete minting (the approved derived key requires a small amount of DeSo - please do not reload).</p>"
          // check whitelist
          console.log("The Data")
          console.log(data)
          if (data[1] == "1") {
            //check free pass
            if (data[3] == "1") {
              localStorage.setItem("freeMint", "1")
              console.log("free mint pass");
              $("#bid-button").removeClass("hidden");
              $("#login").addClass("hidden");
              $("#bid").removeClass("hidden");
              $("#login-button").addClass("hidden")
              $("#login-button").html("Login")
              $("#login-button").removeClass("is-loading")
              $("#instructions").html(freeMintInstructions);
              $("#instructions").removeClass("hidden", 500);
              $("#formname").removeClass("hidden", 500)
              $("#options").removeClass("hidden", 500)
              $("#price").addClass("hidden", 500);



            } else {
              localStorage.setItem("freeMint", "0")
              //check wallet
              if (data[2] == "1") {

                if (data[0] == "1") {
                  $("#ban-status").removeClass("hidden").html('<p>Our system has detected that you tried to bypass our minting system by unapproving your derived DeSo key. If do do this again you will be banned for 24 hours. If you think this is in error, please reach out to us <a href="https://diamondapp.com/u/ClaraMouse" class="link-danger" target="_blank">@ClaraMouse</a></p>');
                  console.log("ban status 1");
                  $("#login").addClass("hidden", 500)
                  $("#login-button").addClass("hidden", 500)
                  $("#login-button").removeClass("is-loading", 500)
                  $("#login-button").html("Login with DeSo")
                  $("#instructions").removeClass("hidden", 500);
                  $("#options").removeClass("hidden", 500)
                  $("#price").addClass("hidden", 500);

                  $("#instructions").html(mintInstructions);



                  //var transactionHex = data[0];
                  //var nftAddress = data[1];
                  //localStorage.setItem("transactionHex", transactionHex);
                  //localStorage.setItem("nftAddress", nftAddress);

                  //console.log("Trying to change location of identity window");
                  //identityWindow.location.href='https://identity.deso.org/approve?tx=' + localStorage.getItem("transactionHex");
                  //identityWindow = window.open('https://identity.deso.org/approve?tx='
                  //console.log("Thinks it sent")
                  //$("#bid-button").html("Mint NFT")
                  //$("#bid").addClass("hidden", 500)
                  //$("#instructions").addClass("hidden", 500)
                  //$("#bid-status").removeClass("hidden", 500).html("<span class='spinner-border spinner-border-sm' role='status' aria-hidden='true'></span><p>Minting and processing bid...</p>")

                } else if (data[0] == "3") {
                  $("#ban-status").removeClass("hidden").html('<p>Our system has detected that you have tried to bypass our minting system multiple times. You are banned from minting for the next 24 hours. If you think this is in error, please reach out to us <a href="https://diamondapp.com/u/ClaraMouse" class="link-danger" target="_blank">@ClaraMouse</a></p>');
                  console.log("ban status 2");
                  $("#instructions").addClass("hidden", 500)
                  $("#bid").addClass("hidden", 500)
                  $("#bid-button").addClass("hidden")
                  
                } else {
                  console.log("Not on blacklist")
                  $("#bid").removeClass("hidden", 500);
                  $("#login").addClass("hidden", 500);
                  $("#login-button").html("Login")
                  $("#login-button").addClass("hidden", 500);
                  $("#login-button").removeClass("is-loading", 500);
                  $("#instructions").removeClass("hidden", 500);
                  $("#options").removeClass("hidden", 500)
                  $("price").addClass("hidden", 500)
                  $("#instructions").html(mintInstructions, 500)
                  $("#bid-button").removeClass("hidden", 500)
                }

              } else {
                // not enough funds
                console.log("User does not have enough funds")
                $("#ban-status").removeClass("hidden").html("<p>Sorry, do not have enough funds to mint a Jiggler NFT. Please deposit funds into your DeSo account, reload this page and try again.</p>");
                $("#login").addClass("hidden", 500)
                $("#login-button").addClass("hidden", 500)
                $("#login-button").removeClass("is-loading", 500)
                $("#instructions").addClass("hidden", 500)
                $("#bid").addClass("hidden", 500)
                $("#bid-button").addClass("hidden")


              }
            }
          } else if (data[1] == "2") {
            // User has minted max number of NFTs allowed during whitelist phase
            $("#ban-status").removeClass("hidden").html('<p>Sorry, you have minted the max number of NFTs allowed during the Whitelist phase.</p>');
                  console.log("max number of NFTs during whitelist");
                  $("#instructions").addClass("hidden", 500)
                  $("#bid").addClass("hidden", 500)
                  $("#bid-button").addClass("hidden")
                  $("#login-button").addClass("hidden")
                  $("#login").addClass("hidden", 500)
                  $("#login-button").removeClass("is-loading", 500)
          } else if (data[1] == "3") {
            // User has minted max number of NFTs
            $("#ban-status").removeClass("hidden").html('<p>Sorry, you have minted the max number of NFTs allowed.</p>');
                  console.log("max number of NFTs minted");
                  $("#instructions").addClass("hidden", 500)
                  $("#bid").addClass("hidden", 500)
                  $("#bid-button").addClass("hidden")
                  $("#login").addClass("hidden", 500)
                  $("#login-button").addClass("hidden", 500)
                  $("#login-button").removeClass("is-loading", 500)
          
          } else {
            //user not on whitelist
            console.log("User not on whitelist")
            $("#ban-status").removeClass("hidden").html("<p>Sorry, you're not on the whitelist.</p>");
            $("#instructions").addClass("hidden", 500)
            $("#bid").addClass("hidden", 500)
            $("#bid-button").addClass("hidden")
            $("#login").addClass("hidden", 500)
            $("#login-button").addClass("hidden", 500)
            $("#login-button").removeClass("is-loading", 500)
          }



        },
        error: function (jqXHR, textStatus, errorThrown) {
          // show an error message

          console.log("Thinks there was an error checking blacklist")
        }
      });
        }
      });
    } else {
      // Minter is logged in - do not allow to mint
      console.log("Minter logged in")
            $("#ban-status").removeClass("hidden").html("<p>You cannot use the minter account to mint - please use another account.</p>");
            $("#login").addClass("hidden", 500)
            $("#instructions").addClass("hidden", 500)
            $("#bid").addClass("hidden", 500)
            $("#bid-button").addClass("hidden")

    }
    
  } else {
    console.log("At logout");
    localStorage.setItem("userPublicKey", "");
      if (identityWindow) {
        identityWindow.close();
        identityWindow = null;
      }
    $("#login").removeClass("hidden")
    userProfile = ""
    $("#login-button").removeClass("is-loading");
                      $("#bid").addClass("hidden")
                      $("#login-button").html("Login")
                      $("#instructions").addClass("hidden", 500);
                      $("#options").addClass("hidden", 500)
                      $("price").addClass("hidden", 500)
                      $("#bid-button").addClass("hidden", 500)
                      $("#bid-status").addClass("hidden", 500)
                      $("#minted-nft").addClass("hidden", 500)
                      $("#navbarlogin").html(userProfile);
                      $("#navbarloginbutton").removeClass("hidden", 500)
                      
              $("#navbarlogin").removeClass("has-dropdown");
              $("#navbarlogin").removeClass("is-hoverable");
    
    
  }

  if (payload.hasOwnProperty('signedTransactionHex')) {
    console.log("payload is defined");
    console.log(payload);
    if (typeof payload.signedTransactionHex == 'undefined') {
      console.log("payload Signed Transaction is undefined - user cancelled transaction");
      $("#instructions").removeClass("hidden", 500);
      $("#instructions").html('You did not approve the bid transaction. Please click the button to try again. Please click "Approve" when the DeSo Identity window pops up.');
      $("#bid-button").attr("onclick", "retryApprove()");
      $('#processing-quote').addClass("hidden")
      $("#bid-button").html("Retry Minting NFT");
      $("#bid-button").removeClass("hidden");
      $("#options").removeClass("hidden", 500)
      $("#bid-status").addClass("hidden", 500);
      $("#minted-nft").addClass("hidden", 500);
      $('#formname').removeClass("hidden", 500)
      return

    }
  }
}

function scrollToBid() {
  $([document.documentElement, document.body]).animate({
    scrollTop: $("#topofmint").offset().top
}, 1000);
}

function handleDerive(payload) {
  console.log(payload);
  console.log("at derive")
  //if (identityWindow) {
  //identityWindow.close();
  //identityWindow = null;

  //var element = document.getElementById('loggedin');
  //element.innerText = 'Logged in with derived key as ' + payload.publicKeyBase58Check;
  //}



  if (identityWindow) {
    identityWindow.close();
    identityWindow = null;


    if (payload.derivedSeedHex && mintType == 'mint') {
      console.log("have derived key")
      publicKey = payload.publicKeyBase58Check;


      localStorage.setItem("userPublicKey", payload.publicKeyBase58Check);
      localStorage.setItem("derivedPublicKeyBase58Check", payload.derivedPublicKeyBase58Check);
      localStorage.setItem("derivedJWT", payload.derivedJwt);
      localStorage.setItem("derivedSeedHex", payload.derivedSeedHex);
      localStorage.setItem("accessSignature", payload.accessSignature);
      localStorage.setItem("expirationBlock", payload.expirationBlock);
      localStorage.setItem("transactionSpendingLimitHex", payload.transactionSpendingLimitHex);
      console.log("Local Storage");
      console.log(localStorage.getItem("userPublicKey"));
      console.log(localStorage.getItem("derivedSeedHex"));

      //Now we are minting because we clicked mint
      console.log("Got Signed");
    if (identityWindow) {
      identityWindow.close();
      identityWindow = null;
    }

    var userPublicKey = localStorage.getItem("userPublicKey");
  var derivedPublicKey = localStorage.getItem("derivedPublicKeyBase58Check");
  var derivedSeedHex = localStorage.getItem("derivedSeedHex");
  var derivedJWT = localStorage.getItem("derivedJWT");
  var accessSignature = localStorage.getItem("accessSignature");
  var expirationblock = localStorage.getItem("expirationBlock");
  var transactionSpendingLimitHex = localStorage.getItem("transactionSpendingLimitHex");
  $("#ban-status").addClass("hidden", 500)


  nftSocket = new WebSocket("wss://1qjwwnpq21.execute-api.us-west-2.amazonaws.com/production");
  nftSocket.onopen = function (e) {
    console.log("Connected to socket");

    console.log("Sending first message to initate mint");
    //nftSocket.send(message);

    //var data = {
    //  action : "update"}
    //var message = JSON.stringify(data);
    //socket.send(message);
    //var message = JSON.stringify({action: "approve-bid-queue", message: "Hello"});
    //socket.send(message);
  };
  var nftName = $("#name").val(); 
  var dao = "";
  var data = {
    action: "mintwebsocket",
    type: "website",
    nftName: nftName,
    dao: dao,
    userPublicKey: userPublicKey,
    derivedPublicKey: derivedPublicKey,
    derivedJWT: derivedJWT,
    derivedSeedHex: derivedSeedHex,
    accessSignature: accessSignature,
    expirationblock: expirationblock,
    transactionSpendingLimitHex: transactionSpendingLimitHex
  }
  var message = JSON.stringify(data);
  sendMessage(nftSocket, message)
  sendMessage(nftSocket, JSON.stringify({ action: 'update' }))




  nftSocket.onmessage = function (event) {
    console.log("Message received from websocket in bid");
    console.log(event.data);
    data = JSON.parse(event.data);
    console.log(data.Status);
    if (data.Status == "Accepting Bid") {
      console.log("Here's the status: " + data.Status);
      console.log("Here's the queue wait time: " + data.ApproximateReceiveCount);
    } else if (data.Status == "Minting") {
      console.log("Here's the status: " + data.Status);
      console.log("Here's the queue wait time: " + data.ApproximateReceiveCount);
    } else if (data.Status == "NFTs are sold out!") {
      $("#clock").addClass("hidden")
      clearInterval(myTimer)
      $("#bid-status").removeClass("hidden", 500).html("<p class='fw-bold error'>" + NFTsoldOutMessage + "</p>")
      $("#ban-status").addClass("hidden", 500)
      $("#processing-quote").addClass("hidden", 500)
      $("#bid-button").html("Mint")
      $("#bid-button").removeClass("is-loading")
      $("#bid-button").addClass("hidden", 500)
      $("#instructions").addClass("hidden", 500)
      console.log("Thinks nfts are sold out")
    } else if (data.Status == "Complete") {
      console.log("Completed minting")
      $("#clock").addClass("hidden")
      //$("#processing-quote").addClass("hidden")
      clearInterval(myTimer)
      nftSocket.close()
      console.log("NFT Attributes")




                              var nftID = data.Attributes.Edition;
                              var imageURL = data.Attributes.ImageURL;
                              var PostHashHex = data.Attributes.PostHashHex;
                              var traits = {};
                              
                        
                              for (const [key, value] of Object.entries(data.Attributes)) {
                                //console.log(key, value);
                                if(key.startsWith('Trait_')) {
                                  //console.log("We have a trait");
                                  //console.log(key);
                                  //console.log(value);
                                  traits[key.slice(6)] = value;
                        
                                }
                              }
                              //console.log(traits);
                              
                              //let traitString = [...traits].map(([k, v]) => `${k}: ${v}`).join(" • ");
                              let traitString = `${Object.keys(traits).map(key => `${key}: ${traits[key]}`).join(' • ')}`;
    

      nftCard = "\
  <div class='nft'>\
    <div class='main'>\
        <h1 class='title is-light mb-2 mt-2'>Jigglers " + nftID + "</h1>\
        <img class='tokenImage is-square' src='" + imageURL + "' alt='Jigglers " + nftID + "' width='480px' height='480px'>\
        <div class='has-text-centered'>\
        <div class='accordion'>\
            <div class='accordion-option'>\
                <input type='checkbox' id='toggle1' class='accordion-toggle' />\
                <label class='accordion-title' for='toggle1'>\
                    Traits\
                </label>\
                <div class='accordion-content'>\
                    <p>" + traitString + "</p>\
                </div>\
            </div>\
            </div>\
                </div>\
                      <div class='columns mt-3'>\
                           <div class='column'>\
                           <a class='button is-link is-fullwidth is-outlined' href='https://nftz.zone/nft/" + PostHashHex + "/' target='_blank'>View NFT</a>\
                           </div>\
                           <div class='column'>\
                               <button onclick='scrollToBid()' class='button is-primary is-fullwidth'>Mint One More</button>\
                           </div>\
                         </div>\
    </div>\
</div>"

      $("#instructions").addClass("hidden")
      $("#bid-status").removeClass("hidden").html("<p>You've minted a Jiggler!</p>")
      $("#minted-nft").removeClass("hidden").html(nftCard)
      console.log("Thinks it sent inside signed")
      $("#options").removeClass("hidden", 500)
      $("#name").val("")
      $("#formname").removeClass("hidden", 500)
      $("#seelz-no").prop("checked", true);
      $('#bid-button').removeClass("hidden")
      $('#processing-quote').addClass("hidden")
      $("#login-button").html("Login")
      $('#login').addClass("hidden")
      localStorage.setItem("freeMint", "0")
      $('#login-button').removeClass("is-loading")
      $("#bid-button").html("Mint one more")
      $('#bid').removeClass("hidden")
      

      //Confetti
      var count = 200;
      var defaults = {
        origin: { x: 0.5 }
      };

      function fire(particleRatio, opts) {
        confetti(Object.assign({}, defaults, opts, {
          particleCount: Math.floor(count * particleRatio)
        }));
      }

      fire(0.25, {
        spread: 26,
        startVelocity: 55,
        origin: { x: 0 }
      });
      fire(0.25, {
        spread: 26,
        startVelocity: 55,
        origin: { x: 1 }
      });
      fire(0.2, {
        spread: 60,
        origin: { x: 1 }
      });
      fire(0.2, {
        spread: 60,
        origin: { x: 0 }
      });
      fire(0.35, {
        spread: 100,
        decay: 0.91,
        scalar: 0.8,
        origin: { x: 1 }
      });
      fire(0.35, {
        spread: 100,
        decay: 0.91,
        scalar: 0.8,
        origin: { x: 0 }
      });
      fire(0.1, {
        spread: 120,
        startVelocity: 25,
        decay: 0.92,
        scalar: 1.2,
        origin: { x: 1 }
      });
      fire(0.1, {
        spread: 120,
        startVelocity: 25,
        decay: 0.92,
        scalar: 1.2,
        origin: { x: 0 }
      });
      fire(0.1, {
        spread: 120,
        startVelocity: 45,
        origin: { x: 1 }
      });
      fire(0.1, {
        spread: 120,
        startVelocity: 45,
        origin: { x: 0 }
      });
      //Reload Gallery
      $('#grid').addClass("hidden");
      $('#gallery-loading').removeClass("hidden");
      $('#grid').mediaBoxes('destroy');
      $('#grid').html("");
      console.log("Getting gallery");
      getGallery(processGallery);
            

    } else if (data.Status == "Update") {
      console.log("Here's the status: " + data.Status);
      console.log("Here's the queue wait time: " + data.ApproximateNumberOfMessages);
      if (data.ApproximateNumberOfMessages == 0) {
        console.log("Wait time is 0");
        var queueWaitTime = "60";
      } else {
        var queueWaitTime = (parseInt(data.ApproximateNumberOfMessages) * 30);
        if (queueWaitTime < 60) {
          console.log("Wait time is less than a minute");
          queueWaitTime = "60";
        } else {
          console.log("Wait time is greater than a minute");
          queueWaitTime = queueWaitTime.toString();

        }
      }
      //identityWindow.opener.$("#wait-time").html(queueWaitTime)
      console.log("Writing wait time")
      //$( "#wait-time", identityWindow.window.document.body ).html(queueWaitTime)
      //identityWindow.document.$("#wait-time").html(queueWaitTime)
      //identityWindow.window.document.body.innerHTML
      //identityWindow.document.getElementById("time").innerText = queueWaitTime;
      console.log("Writing wait time")
      $("#clock").html("Estimating wait time...")
      clock(queueWaitTime);
      $("#clock").removeClass("hidden", 500)
      $("#processing-quote").removeClass("hidden", 500)


    } else if (data.Status == "Accepting Bid") {
      console.log("Here's the status: " + data.Status);
      console.log("Here's the queue wait time: " + data.ApproximateReceiveCount);
      if (data.ApproximateReceiveCount <= 1) {
        console.log("Wait time is 1 or less");
      } else {
        var queueWaitTime = (parseInt(data.ApproximateReceiveCount) * 30);
        if (queueWaitTime < 60) {
          console.log("Wait time is less than a minute");
        } else {
          console.log("Wait time is greater than a minute");
          queueWaitTime = queueWaitTime.toString();

        }
      }
      $('#instructions').addClass("hidden")

    } else if (data.Status == "Minting") {
      console.log("Here's the status: " + data.Status);
      console.log("Here's the queue wait time: " + data.ApproximateReceiveCount);
      if (data.ApproximateReceiveCount <= 1) {
        console.log("Wait time is 1 or less");
      } else {
        var queueWaitTime = (parseInt(data.ApproximateReceiveCount) * 30);
        if (queueWaitTime < 60) {
          console.log("Wait time is less than a minute");
        } else {
          console.log("Wait time is greater than a minute");
          queueWaitTime = queueWaitTime.toString();
          clock(queueWaitTime);

        }
      }
    } else if (data.Status == "Update") {
      console.log("Here's the status: " + data.Status);
      console.log("Here's the queue wait time: " + data.ApproximateNumberOfMessages);
      if (data.ApproximateNumberOfMessages == 0) {
        console.log("Wait time is 0");
        var queueWaitTime = "60";
      } else {
        var queueWaitTime = (parseInt(data.ApproximateNumberOfMessages) * 30);
        if (queueWaitTime < 60) {
          console.log("Wait time is less than a minute");
          queueWaitTime = "60";
        } else {
          console.log("Wait time is greater than a minute");
          queueWaitTime = queueWaitTime.toString();
          clock(queueWaitTime);

        }
      }
      //identityWindow.opener.$("#wait-time").html(queueWaitTime)
      console.log("Writing wait time")
      $("#clock").html("Estimating wait time...")
      clock(queueWaitTime);
      $("#clock").removeClass("hidden", 500)
      $("#processing-quote").removeClass("hidden", 500)
    }
    else if (data.Status == "Ready to sign") {
      localStorage.setItem("transactionHex", data.BidTransferHex);
      localStorage.setItem("nftAddress", data.postHashHex);
      localStorage.setItem("Seelz", data.Seelz);
      console.log("Trying to change location of identity window");
      identityWindow.location.href = 'https://identity.deso.org/approve?tx=' + localStorage.getItem("transactionHex");
    }
    else if (data.Error) {
      console.log("Here's the error: " + data.Error);
      $("#clock").addClass("hidden")
      $("#processing-quote").addClass("hidden")
      clearInterval(myTimer)
      $("#instructions").addClass("hidden", 500)
      $("#bid").addClass("hidden", 500)
      $("#bid-status").removeClass("hidden", 500).html("<p class='error'>" + data.Error + " Please try again later.</p>")
      $("#bid-button").html("Mint NFT")
      $('#processing-quote').addClass("hidden")
      $("#bid-button").removeClass("is-loading")
      $("#login-button").html("Login with DeSo")
      $("#login-button").removeClass("is-loading")
      $("#login").removeClass("hidden", 500)
      $('#formname').removeClass("hidden", 500)
    } else if (data.message == "Internal server error") {
      console.log("Here's the error: " + data.message);
      $("#bid-status").removeClass("hidden", 500).html("<p class='error'>Sorry, there was an error. Please try again later. ERROR: " + data.message + "</p>")
      $("#bid-button").removeClass("is-loading")
      $("#bid-button").html("Mint NFT")
      $('#processing-quote').addClass("hidden")
      $("#options").removeClass("hidden", 500)
      $("#bid").removeClass("hidden", 500)
      $('#formname').removeClass("hidden", 500)
      console.log("Thinks there was an error")
      identityWindow.close()

    }
    else if (data.Status == "Ready to sign") {
      localStorage.setItem("transactionHex", data.BidTransferHex);
      localStorage.setItem("nftAddress", data.postHashHex);
      //localStorage.setItem("Seelz", data.Seelz);
      console.log("Trying to change location of identity window");
      identityWindow.location.href = 'https://identity.deso.org/approve?tx=' + localStorage.getItem("transactionHex");
      nftSocket.close()
    } else if (data.Status == "NFTs are sold out!") {
      identityWindow.close()
      nftSocket.close()
      $("#bid-status").removeClass("hidden", 500).html("<p class='error'>Jigglers are sold out!</p>")
      $("#ban-status").addClass("hidden", 500)
      $("#bid-button").removeClass("is-loading")
      $("#bid-button").html("Mint NFT")
      $('#processing-quote').addClass("hidden")
      $("#bid-button").addClass("hidden", 500)
      $("#instructions").addClass("hidden", 500)
      console.log("Thinks nfts are sold out")


    }
    else if (data.Error) {
      console.log("Here's the error: " + data.Error);
      $("#instructions").addClass("hidden", 500)
      $("#options").addClass("hidden", 500)
      $("#bid").addClass("hidden", 500)
      $("#bid-status").removeClass("hidden", 500).html("<p class='error'>Sorry, there was an error. Please try again later. <BR>ERROR: " + data.Error + "</p>")
      setTimeout(function () {
        $("#bid-status").addClass("hidden", 500)
        $("#bid-button").removeClass("is-loading")
        $("#bid-button").html("Mint NFT")
        $('#processing-quote').addClass("hidden")
        $("#options").removeClass("hidden", 500)
        $('#formname').removeClass("hidden", 500)
        $("#bid").removeClass("hidden", 500)
      }, 10e3);

      console.log("Thinks there was an error")
      identityWindow.close()
    }
    else if (data.message == "Internal server error") {
      console.log("Here's the error: " + data.message);
      $("#bid-status").removeClass("hidden", 500).html("<p class='error'>Sorry, there was an error. Please try again later. <BR>ERROR: " + data.message + "</p>")

      $("#bid-button").html("Mint NFT")
      $("#bid-button").removeClass("is-loading")
      $('#processing-quote').addClass("hidden")
      $("#options").removeClass("hidden", 500)
      $("#bid").removeClass("hidden", 500)
      $('#formname').removeClass("hidden", 500)
      console.log("Thinks there was an error")
      identityWindow.close()

    }


  };



    } else {
      console.log("No derived key");
    }
  }
}





function respond(e, t, n) {
  console.log("Respond");

  e.postMessage({
    id: t,
    service: "identity",
    payload: n
  }, "*")
}

function respondApprove(e, t, n) {
  console.log("Respond Approve");

  e.postMessage({
    id: t,
    method: "sign",
    service: "identity",
    payload: n
  }, "*")
  if (identityWindow) {
    identityWindow.close();
    identityWindow = null;
  }
}

function postMessage(e) {
  init ? this.iframe.contentWindow.postMessage(e, "*") : pendingRequests.push(e)
}

// const childWindow = document.getElementById('identity').contentWindow;
window.addEventListener('message', message => {
  console.log("constant");
  console.log('message: ');
  console.log(message);

  const { data: { id: id, method: method, payload: payload } } = message;

  console.log(id);
  console.log(method);
  console.log(payload);

  if (payload?.signedTransactionHex) {
    

  }
  else if (method == 'initialize') {
    handleInit(message);
  } else if (method == 'login') {
    handleLogin(payload);
  } else if (method == 'derive') {
    handleDerive(payload);
  }
});

var init = false;
var iframe = null;
var pendingRequests = [];
var identityWindow = null;

function bid1() {
  $("#bid-button").html("<span class='spinner-border spinner-border-sm' role='status' aria-hidden='true'></span> Processing...")
  e = $(this).siblings(".bid")
  var o;
  var userPublicKey = localStorage.getItem("userPublicKey");
  var data = {
    userPublicKey: userPublicKey
  }
  $.ajax({
    url: "",
    type: "post",
    dataType: "json",
    crossDomain: "true",
    contentType: "application/json; charset=utf-8",
    data: JSON.stringify(data),

    beforeSend: function (data) {
      // nothing
    },

    success: function (data) {
      console.log(data);
      //o = "<p class='form-message form-success'>You're on the whitelist!</p>";
      //e.addClass("hidden").html(o)


      //console.log("Thinks it sent to whitelist")
      //$("#whitelist-button").html("Join Whitelist")
      //$("#whitelistform").addClass("hidden")
      //$("#whitelist-status").removeClass("hidden").html("<p>You're on the whitelist!</p>")

    },
    error: function (jqXHR, textStatus, errorThrown) {
      // show an error message
      var errorMessage = 'Sorry, there was an error. Please try again later. ERROR: ' + jqXHR.status;
      o = '<p class="form-message form-error">' + errorMessage + '</p>';
      e.removeClass("hidden").html(o);
      setTimeout(function () {
        e.addClass("hidden", 500).html("");
      }, 5e3);
      $('#submit-whitelist').attr('disabled', false);
      console.log("Thinks there was an error")
      $("#whitelist-button").html("Join Whitelist")

    }
  });


}
